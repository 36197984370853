import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Blog } from '../models/blog.model'
import SecondaryLayout from "../components/common/secondary-layout"
import './index.scss'
import moment from "moment"


const formatedText = (author: string, date: string) => {
	return `${author} on ${moment(date).format('MMM Do YYYY')}`
}

/*
const BlogPage = ({ data }: { data: { strapiBlog: Blog } }) => {
  const blog = data.strapiBlog
  return (
    <SecondaryLayout
      side={
        <div className="privacy-main-container--side">
					<div className="blog-page__description">
						<h2 className="privacy-main-container--side__title">Blog</h2>
						<div className="privacy-main-container--side__description">
							Zenpli's blog features content on our partnerships, case studies, thought leadership, and product philosophy.
						</div>
					</div>
        </div>
      }

      main={
        <React.Fragment>
          <GatsbyImage
            image={getImage(blog?.cover?.localFile as any)!}
            alt={blog?.cover?.alternativeText}
            className="blog-page__image" />
          <h1 className="blog-page__title">{blog.title}</h1>
					<div className="blog-page__subtitle"
            dangerouslySetInnerHTML={{
              __html: blog?.quote?.data?.childMarkdownRemark?.html,
            }}/>
					<small className="blog-page__author">
						<span className="blog-card--date__icon icon icon-calendar" />
						{formatedText(blog?.writter?.name, blog?.createdAt)}
					</small>
          <div className="blog-page__content"
            dangerouslySetInnerHTML={{
              __html: blog?.body?.data?.childMarkdownRemark.html,
            }}
          />

        </React.Fragment>
      }
    />
  )
}
*/

const BlogPage = ({ data }: { data: { strapiBlog: Blog } }) => <></>


export const Head = () => (
  <>
    <title>Zenpli » Blog</title>
    <meta name="description" content="Zenpli » Blog" />
  </>
)

/*
export const pageQuery = graphql`
  query ($id: String) {
    strapiBlog(id: { eq: $id }) {
      id
      createdAt
      title
       writter {
         name
         email
       }
       quote {
         data {
           quote
           childMarkdownRemark {
             html
             rawMarkdownBody
           }
         }
       }
       body {
         data {
           body
           childMarkdownRemark {
             html
             rawMarkdownBody
           }
         }
       }
       cover {
         alternativeText
         localFile {
           url
           childImageSharp {
             gatsbyImageData
           }
         }
       }
    }
  }
`
*/
export default BlogPage